import React, { useState } from 'react';

// Styling
import '../UIStyle.css';

// Components
import { BackendButton } from '../BackendButton';
import TextInput from '../TextInput';
import ShowResults from '../ShowResults'

// Outside libraries
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';



export default function PiSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [result, setResult] = useState('');

  const handleInputChange = (searchReturn) => {
    setSearchTerm(searchReturn);
  };

  const handleResult = (result) => {
    setResult(result);
  };


  const params = {
    search_term: searchTerm,
  };

  const isSmallScreen = useMediaQuery('(max-width:960px)');

  return (
    <div className='mainView'>
      <h1>Pi Search</h1>
      <Stack className='Stack--style' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <TextInput
          onChange={handleInputChange}
          inputLabel="Search Term"
        />
        <BackendButton
          className='btns'
          backendRoute='https://api.rehimacevedo.com/pi-search/'
          params={params}
          onResult={handleResult}
          label="Search"
        >
          Search Pi
        </BackendButton>
      </Stack>

      <Stack className='Stack--style--text' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <ShowResults inputText={result}/>
      </Stack>
    </div>
  );
};