import React from 'react';
import { HeroButton } from './HeroButton';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      {/* <video src='/videos/video-2.mp4' autoPlay loop muted /> */}
      <h1>Let's Build Together</h1>
      <div className='hero-btns'>
        <HeroButton className='btns' linkPath='https://github.com/joel-1080p' buttonStyle='custom--btn--outline' buttonSize='custom--btn--large'>My Github</HeroButton>
        <HeroButton className='btns' linkPath='/my-applications' buttonStyle='custom--btn--primary' buttonSize='custom--btn--large'>Applications</HeroButton>
      </div>
    </div>
  );
}

export default HeroSection;  
