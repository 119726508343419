// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/hero-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
  
.hero-container {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}
s
.hero-container > p {
  margin-top: 8px;
  color: #fff ;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .custom--btn {
  margin: 6px;
}

@media screen and (max-width: 960px){
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .custom--btn {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HeroSection.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iFAAmF;EACnF,aAAa;EACb,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iDAAiD;EACjD,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;AACA;;EAEE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,qGAAqG;AACvG;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["video {\r\n  object-fit: cover;\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  z-index: -1;\r\n}\r\n  \r\n.hero-container {\r\n  background: url('/public/images/hero-background.jpg') center center/cover no-repeat;\r\n  height: 100vh;\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);\r\n  object-fit: contain;\r\n}\r\n\r\n.hero-container > h1 {\r\n  color: #fff;\r\n  font-size: 100px;\r\n  margin-top: -100px;\r\n}\r\ns\r\n.hero-container > p {\r\n  margin-top: 8px;\r\n  color: #fff ;\r\n  font-size: 32px;\r\n  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;\r\n}\r\n\r\n.hero-btns {\r\n  margin-top: 32px;\r\n}\r\n\r\n.hero-btns .custom--btn {\r\n  margin: 6px;\r\n}\r\n\r\n@media screen and (max-width: 960px){\r\n  .hero-container > h1 {\r\n    font-size: 70px;\r\n    margin-top: -150px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .hero-container > h1 {\r\n    font-size: 50px;\r\n    margin-top: -100px;\r\n  }\r\n\r\n  .hero-container > p {\r\n    font-size: 30px;\r\n  }\r\n\r\n  .btn-mobile {\r\n    display: block;\r\n    text-decoration: none;\r\n  }\r\n\r\n  .custom--btn {\r\n    width: 100%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
