import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Styling
import './UIStyle.css';

export default function ShowResults({inputText}) {
    return (
        <Box
            component="form"
        >
            <Typography fontSize="22px" padding={2} variant="body1" gutterBottom>
                {inputText}
            </Typography>
        </Box>
    );
};



// import { useState, useEffect } from 'react';

// const ShowResults = ({ text, delay}) => {
//   const [currentText, setCurrentText] = useState('');
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     let timeout;

//     if (currentIndex <= text.length) {
//       timeout = setTimeout(() => {
//         setCurrentText(prevText => prevText + text[currentIndex]);
//         setCurrentIndex(prevIndex => prevIndex + 1);
//       }, delay);

//     } 

//     return () => clearTimeout(timeout);
//   }, [currentIndex, delay, text]);

//   return <span>{currentText}</span>;
// };

// export default ShowResults;