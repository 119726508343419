import React from 'react';

import '../ContactMe.css';

import ContactCard from '../ContactCard';
import ContactCardLink from '../ContactCardLink';

import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';

import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';

export default function ContactMe(){
    const isSmallScreen = useMediaQuery('(max-width:960px)');
    return (
        <div className='mainView'>
            <h1>Contact</h1>
            <Stack
                className={isSmallScreen ? 'Stack--style' : 'Stack--style--profile'}
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
            >
                <Card variant="outlined" sx={{ width: 320 }}>
                    <CardOverflow>
                        <AspectRatio ratio="1.25">
                        <img
                            src={process.env.PUBLIC_URL + '/images/profile-picture.jpg'}
                            loading="lazy"
                            alt=""
                        />
                        </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                        <Typography level="title-md">Raimond Joel Acevedo</Typography>
                        <Typography level="body-sm">Ashland University 2015</Typography>
                    </CardContent>
                </Card>
                <Stack className='Stack--small' direction="column" spacing={1}>
                    <ContactCard 
                        title = "Email"
                        description = "joel08acevedo@gmail.com"
                        icon = {process.env.PUBLIC_URL + '/images/icon-email.png'}
                    />
                    <ContactCardLink 
                        title = "Resume"
                        description = "Tap or click for Resume"
                        icon = {process.env.PUBLIC_URL + '/images/icon-resume.png'}
                        cardlink = {process.env.PUBLIC_URL + 'Files/Resume - Raimond Acevedo.pdf'}
                    />
                </Stack>
            </Stack>
            <Stack
                className={isSmallScreen ? 'Stack--style' : 'Stack--style--profile'}
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
            >
                <Stack
                    className= 'Stack--small'
                    direction={isSmallScreen ? "column" : "row"}
                    spacing={1}
                >
                    <ContactCardLink 
                        title = "Github"
                        description = "Tap or click for Github"
                        icon = {process.env.PUBLIC_URL + '/images/icon-github.png'}
                        cardlink = "https://github.com/joel-1080p"
                    />
                    <ContactCardLink 
                        title = "LinkedIn"
                        description = "Tap or click for LinkedIn"
                        icon = {process.env.PUBLIC_URL + '/images/icon-linkedin.png'}
                        cardlink = "https://linkedin.com/in/raymond-acevedo-a4b46497"
                    />
                </Stack>
            </Stack>
        </div>
    );
}