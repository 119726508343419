import React, { useState } from 'react';

// Styling
import '../UIStyle.css';

// Components
import { BackendButton } from '../BackendButton';
import DollarInput from '../DollarInput';
import Dropdown from '../Dropdown';
import ShowResults from '../ShowResults'

// Outside libraries
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';



export default function DollarToday() {

  /* State handlers for date, dollar amount, and returned backend value. */
  const [selectedDate, setSelectedDate] = useState(new Date().getFullYear() - 1);
  const [dollarAmount, setDollarAmount] = useState('1');
  const [result, setResult] = useState('');

  /* Holds all of the possible dates to choose from. */
  const dates = []

  /* Gets last year's date. */
  var start_date = new Date().getFullYear() - 1

  /* For loop that runs from last year to 1800 and appends to array.*/
  for(let i = start_date; i > 1799; i--){
    dates.push(i)
  }

  /* Event handlers for changing the date, changing the dollar amount, and returned value. */
  const handleDateChange = (result) => {
    setSelectedDate(result);
  };
  const handleDollarChange = (result) => {
    setDollarAmount(result);
  };
  const handleResult = (result) => {
    setResult(result);
  };

  /* Holds the params for the backend request. */
  const backend_params = {
    dollar_chosen: dollarAmount,
    year_chosen: selectedDate,
  };

  const isSmallScreen = useMediaQuery('(max-width:960px)');

  return (
    <div className='mainView'>
      <h1>$1 Today</h1>
      <Stack className='Stack--style' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <Dropdown
          items={dates}
          onChange={handleDateChange}
          label="Select date"
        />
        <DollarInput
          onChange={handleDollarChange}
          inputLabel="Dollar Amount"
        />
      </Stack>
      <Stack className='Stack--style' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <BackendButton
          className='btns'
          backendRoute='https://api.rehimacevedo.com/dollar-today/'
          params={backend_params}
          onResult={handleResult}
          label="Calculate"
        >
          Calculate
        </BackendButton>
      </Stack>

      <Stack className='Stack--style--text' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <ShowResults inputText={result}/>
      </Stack>
    </div>
  );
}