// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* If the screen is greater than 768 (DESKTOP) */
@media screen and (min-width: 768px) {
  .mainView {
    margin: auto;
    width: 60%;
    padding: 10px;
  }

  .Stack--small {
    width: 80%;
    padding: 10px;
  }
  
}

.Stack--style--profile {
  margin: auto;
  padding: 10px;
  padding-left: 20%;
}

`, "",{"version":3,"sources":["webpack://./src/components/ContactMe.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD;EACE;IACE,YAAY;IACZ,UAAU;IACV,aAAa;EACf;;EAEA;IACE,UAAU;IACV,aAAa;EACf;;AAEF;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB","sourcesContent":["/* If the screen is greater than 768 (DESKTOP) */\r\n@media screen and (min-width: 768px) {\r\n  .mainView {\r\n    margin: auto;\r\n    width: 60%;\r\n    padding: 10px;\r\n  }\r\n\r\n  .Stack--small {\r\n    width: 80%;\r\n    padding: 10px;\r\n  }\r\n  \r\n}\r\n\r\n.Stack--style--profile {\r\n  margin: auto;\r\n  padding: 10px;\r\n  padding-left: 20%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
