import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

/* Styling */
import './App.css';

/* Nav Pages */
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import MyApplications from './components/pages/MyApplications';
import Documentation from './components/pages/Documentation';
import ContactMe from './components/pages/ContactMe';

/* Applications Page */
import DollarToday from './components/pages/DollarToday';
import HorribleHoroscope from './components/pages/HorribleHoroscope';
import JKC from './components/pages/JKC';
import PiSearch from './components/pages/PiSearch';
import WrapNYap from './components/pages/WrapNYap';


/* 404 Error */
import NoPage from './components/pages/NoPage';

function App() {

  return (
    <>
      <Router>
        <Navbar />
          <Routes>
            <Route path='/' exact Component={Home}/>
            <Route path='/home' exact Component={Home}/>
            <Route path='/my-applications' exact Component={MyApplications}/>
            <Route path='/documentation' exact Component={Documentation}/>
            <Route path='/contact-me' exact Component={ContactMe}/>
            <Route path='/dollar-today' exact Component={DollarToday}/>
            <Route path='/horrible-horoscope' exact Component={HorribleHoroscope}/>
            <Route path='/jkc' exact Component={JKC}/>
            <Route path='/pi-search' exact Component={PiSearch}/>
            <Route path='/wrap-n-yap' exact Component={WrapNYap}/>
            <Route path='*' exact Component={NoPage}/>
          </Routes>
      </Router>
    </>
  );
}

export default App;