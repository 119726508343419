import * as React from 'react';

import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import { AspectRatio } from '@mui/joy';
import Link from '@mui/joy/Link';

export default function ContactCardLink(props) {

    console.log(props.clickable)
    return (
        <Card sx={{
                borderColor:'transparent',
                borderRadius: 0, width: 300,
                maxWidth: '100%',
                backgroundColor: "white"
        }}>
            <Link
                overlay
                underline="none"
                href={props.cardlink}
                pointerEvents = {props.clickable}
            >
                <Card
                    orientation="horizontal"
                    size="sm"
                    sx={{ bgcolor: 'background.surface', mb: 1 }}
                >
                    <CardOverflow>
                        <AspectRatio
                            ratio="1"
                            sx={{ minWidth: 70, '& img[data-first-child]': { p: 0.5 } }}
                        >
                            <img src={props.icon}
                            loading="lazy"
                            alt=""
                            />
                        </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                        <Typography level="title-md">{props.title}</Typography>
                        <Typography level="body-sm">{props.description}</Typography>
                    </CardContent>
                </Card>
            </Link>
        </Card>
    );
}
