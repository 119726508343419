import React, { useState } from 'react';

// Styling
import '../UIStyle.css';

// Components
import { BackendButton } from '../BackendButton';
import Dropdown from '../Dropdown';
import ShowResults from '../ShowResults'

// Outside libraries
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';


export default function HorribleHoroscope() {
  const [selectedSign, setSelectedSign] = useState('');
  const [result, setResult] = useState('');

  const handleResult = (result) => {
    setResult(result);
  };

  const signs = [
    'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo',
    'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'
  ];

  const handleSignChange = (sign) => {
    setSelectedSign(sign);
  };

  const params = {
    sign: selectedSign,
  };

  const isSmallScreen = useMediaQuery('(max-width:960px)');

  return (
    <div className='mainView'>
      <h1>Horrible Horoscope</h1>
      <Stack className='Stack--style' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        <Dropdown
          items={signs}
          onChange={handleSignChange}
          label="Select sign"
        />
        <BackendButton
          className='btns'
          backendRoute='https://api.rehimacevedo.com/horrible-horoscope/'
          params={params}
          onResult={handleResult}
          label="Get Horoscope!"
        >
          Get Horoscope!
        </BackendButton>
      </Stack>

      <Stack className='Stack--style--text' direction={isSmallScreen ? "column" : "row"} spacing={2}>
        {/* <ShowResults text={result} delay={30}/> */}
        <ShowResults inputText={result} />
      </Stack>
    </div>
  );
}
