import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function BasicButton({onClick, label}) {

  const handleInputChange = (event) => {
    onClick(event.target.value)
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '29ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Button className='Button' variant="contained" onClick={handleInputChange}>{label}</Button>
    </Box>
  );
}