import React, { useState } from 'react';
import BasicButton from './BasicButton';
import LoadingButtons from './LoadingButtons';


export const BackendButton = ({
  children,
  backendRoute,
  params,
  onResult,
  label
}) => {
  const [_, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = () => {
    setLoading(true);
    setError(null);

    const queryString = new URLSearchParams(params).toString();
    const urlWithParams = `${backendRoute}?${queryString}`;

    fetch(urlWithParams)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setResult(data.text);
        setLoading(false);
        if (onResult) {
          onResult(data.text);
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  return (
    <>
      {!loading &&
        <BasicButton
          onClick={handleClick}
          label={label}
        >
          {children}
        </BasicButton>
      }
      {loading && <LoadingButtons /> }
      {error && <div className="error-text">Error: {error.message}</div>}
    </>
  );
};
