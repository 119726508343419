import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

export default function Dropdown({onChange, items, label}) {

  const handleChange = (event) => {
    onChange(event.target.innerText);
  };
  
  // If the input is anything but a string, it will cast it.
  let items_str = items.toString()
  let final_array = items_str.split(",")
  
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={final_array}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={handleChange}
      />
    </Box>
  );
}

