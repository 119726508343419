import React from 'react';

// Styling
import '../UIStyle.css';

// Outside Components
import Typography from '@mui/material/Typography';


export default function Documentation() {
    return (
        <div className='mainView'>
            <Typography variant="h1" gutterBottom>
                Site Documentation
            </Typography>

        
            <Typography variant="h4" gutterBottom>
                Overview
            </Typography>
            <Typography variant="body1" gutterBottom>
                This portfolio website showcases four unique applications,
                each demonstrating different aspects of web development and data manipulation.
                The website is built using a React JS front end and a Flask backend and is self-hosted
                on a PC at home. Below are detailed descriptions of each application, including
                their functionality, implementation, and technical details.
            </Typography><br/><br/>


            <Typography variant="h4" gutterBottom>
                Hosting Environment
            </Typography>
            <Typography variant="body1" gutterBottom>
                Server: Self-hosted on a single Dell machine<br/>
                Frontend: React JS <br/>
                Backend: Flask <br/>
                Deployment: Local network <br/>
            </Typography><br/><br/>


            <Typography variant="h4" gutterBottom>
                Application : Horrible Horoscope
            </Typography>
            <Typography variant="body1" gutterBottom>
                Get a hilariously twisted take on your daily
                horoscope by revealing the snarky, negative side of your sign.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Implementation
            </Typography>
            <Typography variant="body1" gutterBottom >   
                LLM: qwen2 7b. <br/>
                Custom Prompts: Prompts are tailored to ensure quick and efficient responses due to hardware limitations. <br/>
                Technology Stack: Open Source LLM, Ollama, Python, Flask, React JS <br/>
                Github Link: <a href='https://github.com/joel-1080p/HorribleHoroscope' target="_blank" rel="noreferrer">Horrible Horoscope Github Link</a>
            </Typography>
            <Typography variant="h5" gutterBottom>
                Known Bugs
            </Typography>
            <Typography variant="body1" gutterBottom>
                Instead of referring to you, the reader, it mentions another person
                with the horoscope you chose. It will say you will meet this person
                instead of this person being you.<br />
                This can be fixed by a bigger model, but at the cost of run time.
            </Typography><br/><br/>


            <Typography variant="h4" gutterBottom>
                Application : Wrap-n-Yap
            </Typography>
            <Typography variant="body1" gutterBottom>
            Wrap-n-yap wraps up articles for you, then serves it
            with a side of sound - an audio summary that'll keep you in the know!
            </Typography>
            <Typography variant="h5" gutterBottom>
                Implementation
            </Typography>
            <Typography variant="body1" gutterBottom >   
                LLM: llama 3.1 7b <br/>
                Technology Stack: Open Source LLM, Ollama, Python, Flask, React JS <br/>
                Github Link: <a href='https://github.com/joel-1080p/wrap-n-yap' target="_blank" rel="noreferrer">Wrap-n-Yap Github Link</a>
            </Typography>
            <Typography variant="h5" gutterBottom>
                Known Bugs
            </Typography>
            <Typography variant="body1" gutterBottom>
                If the user makes a request and the request is fulfilled, the user will need to reload the page. <br />
                If the page is not reloaded, the previously ran mp3 will still be there.
            </Typography><br/><br/>


            <Typography variant="h4" gutterBottom>
                Application : Pi Search
            </Typography>
            <Typography variant="body1" gutterBottom>
                This application searches for a word or name within the digits
                of pi by converting alphabetical characters to their T9 numeric equivalents.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Implementation
            </Typography>
            <Typography variant="body1" gutterBottom>
                T9 Conversion: Converts input text to T9 numbers. <br/>
                Pi Digits: Parses the first billion digits of pi stored in a text file to avoid real-time computation. <br/>
                Search Algorithm: Efficiently searches the precomputed pi digits for the T9 sequence. <br/>
                Technology Stack: Python, Flask, React JS <br/>
                Github: <a href='https://github.com/joel-1080p/pi_search' target="_blank" rel="noreferrer">Pi Search Github Link</a>
            </Typography><br/><br/>


            <Typography variant="h4" gutterBottom>
                Application : $1 Today
            </Typography>
            <Typography variant="body1" gutterBottom>
                This tool calculates the equivalent value of a given
                amount of money from a specified past year to today, based on historical CPI data.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Implementation
            </Typography>
            <Typography variant="body1" gutterBottom>
                CPI Data: Stored in a CSV file which is parsed to compute inflation-adjusted values. <br />
                Calculation: Traverses the CPI data to provide accurate inflation-adjusted values for any given year. <br />
                Technology Stack: Python, Flask, JSON, React JS, CSV data manipulation <br />
                Github: <a href='https://github.com/joel-1080p/DollarToday' target="_blank" rel="noreferrer">$1 Today Github Link</a>
            </Typography><br/><br/>


            <Typography variant="h4" gutterBottom>
                Application : JKC (Jalapeno Kettle Chips)
            </Typography>
            <Typography variant="body1" gutterBottom>
                Displays historical prices of Jalapeno Kettle Chips at ShopRite 
                using web scraping techniques and visualizes the data using charts.
            </Typography>
            <Typography variant="h5" gutterBottom>
                Implementation
            </Typography>
            <Typography variant="body1" gutterBottom>
                Web Scraping: Utilizes Selenium to scrape price data from ShopRite’s website. <br/>
                Data Storage: Stores scraped data along with dates and notes in a CSV file. <br/>
                Data Visualization: Uses Apex Charts to present the historical price data. <br/>
                Technology Stack: Python, Flask, React JS, JSON, Selenium, Apex Charts, CSV data manipulation <br/>
                Github: <a href='https://github.com/joel-1080p/JKC' target="_blank" rel="noreferrer">JKC Github Link</a>
            </Typography><br/><br/>

            
        </div>
    );
}