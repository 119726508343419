import React, { useEffect, useState } from 'react';
import JKCChart from '../JKCChart';

export default function JKC(){
    const [data, setData] = useState({ dates: [], prices: [], notes: [] });

    useEffect(() => {
        fetch('https://api.rehimacevedo.com/JKC/')
            .then(response => response.json())
            .then(json => {
                const dates = json.text.map(entry => entry.date);
                const prices = json.text.map(entry => entry.price);
                const notes = json.text.map(entry => entry.notes);
                setData({ dates, prices, notes });
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <>
            <JKCChart prices={data.prices} dates={data.dates} notes={data.notes} />
        </>
    )
}