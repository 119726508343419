import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Applications</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItem
                    src='/images/horrible-horoscope-logo.jpg'
                    text='Horrible Horoscope gets a hilariously twisted take on your daily horoscope by revealing the snarky, negative side of your sign.'
                    label=''
                    path='/horrible-horoscope'
                />
                <CardItem
                    src='/images/wrap-n-yap-logo.jpg'
                    text="Wrap-n-yap wraps up articles for you, then serves it with a side of sound - an audio summary that'll keep you in the know!"
                    label=''
                    path='/wrap-n-yap'
                />
                <CardItem
                    src='/images/dollar-today-logo.png'
                    text='$1 Today lets you see how much $1 today would take you with a given year.'
                    label=''
                    path='/dollar-today'
                />
            </ul>
            <ul className='cards__items'>
                <CardItem
                    src='/images/JKC-logo.jpg'
                    text='JKC lets you See historical prices of Kettle Jalapeno Kettle Chips at ShopRite.'
                    label=''
                    path='/JKC'
                />
                <CardItem
                    src='/images/pi-search-logo.jpg'
                    text='With Pi Search, you can search your name or phrase in Pi.'
                    label=''
                    path='/pi-search'
                />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
