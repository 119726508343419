import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import './UIStyle.css'

export default function TextInput({onChange, inputLabel}) {

  const handleInputChange = (event) => {
    onChange(event.target.value)
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-basic"
        label={inputLabel}
        variant="outlined"
        onChange={handleInputChange}
        type="search"
      />
    </Box>
  );
}


