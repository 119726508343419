import React from 'react';
import { AudioPlayer } from 'react-audio-player-component';

export default function MP3Player(props) {
  return (
    <AudioPlayer 
      src={props.file}
      minimal={true}
      width={350}
      trackHeight={75}
      barWidth={1}
      gap={1}

      visualise={true}
      backgroundColor="#FFFFFF"  //FFF8DE
      barColor="#3874CB" //C1D0B5  242424
      barPlayedColor="#3874CB" // 99A98F

      skipDuration={2}
      showLoopOption={true}
      showVolumeControl={true}

      // seekBarColor="purple"
      // volumeControlColor="blue"
      // hideSeekBar={true}
      // hideTrackKnobWhenPlaying={true}
    />
  )
}