// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #fff;
}

.custom--btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.custom--btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.custom--btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.custom--btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.custom--btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.custom--btn--large:hover,
.custom--btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}`, "",{"version":3,"sources":["webpack://./src/components/HeroButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,iBAAiB;EACjB,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;;EAEE,6BAA6B;EAC7B,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[":root {\r\n  --primary: #fff;\r\n}\r\n\r\n.custom--btn {\r\n  padding: 8px 20px;\r\n  border-radius: 2px;\r\n  outline: none;\r\n  border: none;\r\n  cursor: pointer;\r\n}\r\n\r\n.custom--btn--primary {\r\n  background-color: var(--primary);\r\n  color: #242424;\r\n  border: 1px solid var(--primary);\r\n}\r\n\r\n.custom--btn--outline {\r\n  background-color: transparent;\r\n  color: #fff;\r\n  padding: 8px 20px;\r\n  border: 1px solid var(--primary);\r\n  transition: all 0.3s ease-out;\r\n}\r\n\r\n.custom--btn--medium {\r\n  padding: 8px 20px;\r\n  font-size: 18px;\r\n}\r\n\r\n.custom--btn--large {\r\n  padding: 12px 26px;\r\n  font-size: 20px;\r\n}\r\n\r\n.custom--btn--large:hover,\r\n.custom--btn--medium:hover {\r\n  transition: all 0.3s ease-out;\r\n  background: #fff;\r\n  color: #242424;\r\n  transition: 250ms;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
