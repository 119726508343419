import React, { useState } from 'react';

// Components
import TextInput from '../TextInput';
import { BackendButton } from '../BackendButton';
import ShowResults from '../ShowResults';
import MP3Player from '../MP3Player';

// Outside libraries
import Stack from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';

export default function WrapNYap() {
    const [url, setURL] = useState('');
    const [result, setResult] = useState('');

    const handleInputChange = (URLReturn) => {
        setURL(URLReturn);
    };

    const handleResult = (result) => {
        setResult(result);
    };

    const params = {
        url: url,
    };
    
    const isSmallScreen = useMediaQuery('(max-width:960px)');

    return (
        <div className='mainView'>
            <h1>Wrap-n-Yap</h1>
            <Stack className='Stack--style' direction={isSmallScreen ? "column" : "row"} spacing={2}>
                <TextInput
                    onChange={handleInputChange}
                    inputLabel="Paste URL"
                />
                <BackendButton
                    className='btns'
                    backendRoute='https://api.rehimacevedo.com/wrap-n-yap/'
                    params={params}
                    onResult={handleResult}
                    label="Wrap-N-Yap!"
                />
            </Stack>

            {/* Only show the player if there is change to results. */}
            {result && (
            <Stack className='Stack--style--text' direction={isSmallScreen ? "column" : "row"} spacing={2}>
                <MP3Player file="https://api.rehimacevedo.com/wrap-n-yap-audio/"/>
            </Stack>
            )}
            <Stack className='Stack--style--text' direction={isSmallScreen ? "column" : "row"} spacing={2}>
                <ShowResults inputText={result} />
            </Stack>
        </div>
    );
}