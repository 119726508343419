import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

export default function LoadingButtons() {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '29ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <LoadingButton className='Button' loading variant="contained">
        Submit
      </LoadingButton>
    </Box>
  );
}
