import React from 'react';
import Cards from '../Cards';

function MyApplications(){
    return (
        <>
            <Cards />
        </>
    );
}

export default MyApplications;