// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* If the screen is greater than 768 (DESKTOP) */
@media screen and (min-width: 768px) {
  .mainView {
    margin: auto;
    width: 60%;
    padding: 10px;
  }
  .Stack--style {
    width: 80%;
    padding: 10px;
    /* border: 3px solid green; */
  }
  
  .Stack--style--text {
    margin: auto;
    width: 80%;
  }
}


.Stack--style {
  margin: auto;
  padding: 10px;
}

.Stack--style--profile {
  margin: auto;
  padding: 10px;
  padding-left: 20%;
}

.Button {
  height: 57px;
}

a {
  text-decoration:none;
}

`, "",{"version":3,"sources":["webpack://./src/components/UIStyle.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD;EACE;IACE,YAAY;IACZ,UAAU;IACV,aAAa;EACf;EACA;IACE,UAAU;IACV,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,YAAY;IACZ,UAAU;EACZ;AACF;;;AAGA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["/* If the screen is greater than 768 (DESKTOP) */\r\n@media screen and (min-width: 768px) {\r\n  .mainView {\r\n    margin: auto;\r\n    width: 60%;\r\n    padding: 10px;\r\n  }\r\n  .Stack--style {\r\n    width: 80%;\r\n    padding: 10px;\r\n    /* border: 3px solid green; */\r\n  }\r\n  \r\n  .Stack--style--text {\r\n    margin: auto;\r\n    width: 80%;\r\n  }\r\n}\r\n\r\n\r\n.Stack--style {\r\n  margin: auto;\r\n  padding: 10px;\r\n}\r\n\r\n.Stack--style--profile {\r\n  margin: auto;\r\n  padding: 10px;\r\n  padding-left: 20%;\r\n}\r\n\r\n.Button {\r\n  height: 57px;\r\n}\r\n\r\na {\r\n  text-decoration:none;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
