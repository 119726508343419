import React from 'react';
import ReactApexChart from 'react-apexcharts';

const JKCChart = ({ prices, dates, notes }) => {

  // Min and max of the y axis.
  var graph_y_min = Math.min(...prices) - 1;
  var graph_y_max = Math.ceil(Math.max(...prices));

  // Sets the initial zoom range of 30 days back.
  var initial_x_max = new Date(dates[dates.length - 1]);
  var initial_x_min = new Date(dates[dates.length - 30]);
  var initial_x_range = initial_x_max - initial_x_min;

  // Gets the width of the screen.
  var device_width = window.innerWidth ;

  const chartOptions = {
    chart: {
      toolbar: {
          autoSelected: 'pan',    // Sets pan as default tool to load.
      },
      zoom: {
          type: 'x',
          enabled: true,
      },
      events:{
        // Sets the 'home' button to reset to full chart.
        beforeZoom: function(ctx){
            ctx.w.config.xaxis.range = undefined
        },

        // Gets index of price clicked.
        markerClick: function(event, chartContext, config) {

          // If the notes has something in it.
          // Formats the data before sending to HTML.
          if(isNaN(notes[config.dataPointIndex])){
              var css = '<p style="font-size:24px;color:red;border:2px solid #ff6666;padding 10px;border-radius:5px;display:inline-block;">'
              document.getElementById("sale_price").innerHTML = css + 'SALE : ' + notes[config.dataPointIndex];
          }

          // If the notes does not have anything.
          else{
              document.getElementById("sale_price").innerHTML = '';
          }
        },
      },
    },
    // Sets the x axis data points as the prices.
    series: [
      {
        name: 'JKC',
        data: prices,
      },
    ],
    // Removes the prices from the line chart top.
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'ShopRite Stock Exchange : JKC',
      align: 'left'
    },
    // Gradient fill under line.
    fill: {
      colors: ['#5C8B3E'],
      type: 'gradient',
      gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
      },
    },
    // Attributes for the y axis.
    yaxis: {
      min: graph_y_min,
      max: graph_y_max,
      title: {
          text: 'Price'
      }
    },
    // Attributes for the x axis.
    xaxis: {
      type: 'datetime',
      categories: dates,
      range: initial_x_range,       
    },
    // Line attributes.
    stroke:{
      width: 5,
      colors: ['#5C8B3E'],
    },
  };

  return (
    <>
        <div>
        <ReactApexChart
            options={chartOptions}
            series={chartOptions.series}
            height={450}
            width={device_width - 20}  /* Uses device width plus a 20 pxl buffer.*/
            type="area"
            stacked={false}
        />
        {/* If there is a note, it will center it. */}
        <center>
            <p id="sale_price"></p>
        </center>
        </div>
    </>
  );
};

export default JKCChart;